import axios from 'axios';

/**
 * axios request Interceptors: for each request we set an authorization token if any
 */
axios.defaults.baseURL = ''
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    if (401 === error.response.status) {
        window.location.href = '/users/sign_in';
    } else {
        return Promise.reject(error);
    }
});

export default axios;
