let isPointInPolygon = (point, coords, map) => {
  const polygon = new google.maps.Polygon({ paths: coords });
  let result = google.maps.geometry.poly.containsLocation(
    new google.maps.LatLng(point),
    polygon
  )
  return result
}

export default {

  dayToNumValue: (dayName) => {
    return {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
    }[dayName]
  },

  /**
   * get day delivery fees
   * @param {*} fees 
   * @param {*} day 
   * @returns 
   */
  getDayfee: (fees, day) => {
    let dayFee = fees.find(f => f.code.toLowerCase() == day.toLowerCase())
    return dayFee ? dayFee.fee : 0
  },

  fees: () => {
    return 3000
  },
  
  findLocationZone: (location, zones, map) => {
    return zones.find(zone => isPointInPolygon(location, zone.formattedLocationPoints, map))
  },

  zoneDayToFrench: (day) => {
    const translations = {
      Monday: 'Lundi',
      Tuesday: 'Mardi',
      Wednesday: 'Mercredi',
      Thursday: 'Jeudi',
      Friday: 'Vendredi',
      Saturday: 'Samedi',
      Sunday: 'Dimanche',
    };
    return translations[day];
  },

};