<template>
  <div class="order-item-container">
    <product-template
      :product="item.product"
      :likes="likes"
      :oneShot="item.oneShot"
      :inCart="false"
      :initQuantity="item.quantity"
      :status="PRODUCT_STATUS.validated"
      :showFreq="true"
      :withLikes="true"
    ></product-template>
  </div>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'

export default {
  props: ['item', 'likes'],
  data() {
    return {
      PRODUCT_STATUS,
    }
  },
}
</script>
