<template>
  <div v-if="cart" class="container">
    <div class="row justify-content-center mt-5">
      <a href="/users/sign_in" class="btn btn-huge btn-primary mr-2">Connectez-vous</a>
      <a href="/account/signup" class="btn btn-huge btn-primary ml-2">Créez un compte</a>
    </div>

    <!-- MY CART -->
    <div v-if="cart && cart.order_items.length > 0" class="row p-2 justify-content-center">
      <div class="accordion" id="accordionUnvalidated">
        <div class="card">
          <div class="card-header section-cart-products" id="headingUnvalidated">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseUnvalidated" aria-expanded="true" aria-controls="collapseUnvalidated">
                Les produits séléctionnés
              </button>
            </h2>
          </div>

          <div id="collapseUnvalidated" class="collapse show" aria-labelledby="headingUnvalidated" data-parent="#accordionUnvalidated">
            <div class="card-body">
              <div class="d-flex flex-col flex-wrap justify-content-around">
                <div class="product-container" v-for="item in cartBoxes" v-bind:key="item.id">
                  <h-cart-box-item :key="item.id" :box="item.box" :quantity="item.quantity" :oneShot="item.oneShot" />
                </div>
                <div class="product-container"
                  v-for="item in cartProducts"
                  v-bind:key="item.id"
                >
                  <h-cart-item :item="item" :likes="likes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import service from './packs/services'
import wave from './packs/wave'

export default {
  props: ["userId"],

  data: function () {
    return {
      user: null,
      cart: null,
      boxes: [],
      likes: null
    }
  },
  async mounted() {
    this.refresh()

  },

  computed: {
    cartProducts() {
      return this.cart.order_items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.cart.order_items.filter(item => item.panier)
    },

    orderProducts() {
      return this.order.order_items.filter(item => item.type === 'product')
    },

    /** init boxes */
    orderBoxes() {
      
      let orderedBoxes = []
      this.boxes.forEach(box => {
        let occurrence = this.order.order_items.find(oi => oi.type === 'box' && oi.box_id == box.id)
        if(occurrence) {          
          let productBox = box.box_items.find(b => occurrence.product.id == b.productId)
          box.quantity = occurrence.quantity / productBox.quantity
          box.oneShot = occurrence.oneShot
          orderedBoxes.push(box)
        }
      });
      return orderedBoxes
    }

  },
  methods: {

    async refresh() {
      this.boxes = await service.getBoxes()
      this.cart = await service.getTCartContent()
      this.likes = await service.getLikes()
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      } 
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }  
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;

    @media screen and (min-width: 767px) {
      padding-right: 0.25rem;
    }
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: 1.2rem;
    font-weight: 600;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

.amount {
  margin-left: 10px;
  font-family: 'Recoleta';
  font-weight: bold;
}

.currency {
  font-family: 'Montserrat';
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
  span {
    line-height: 40px;
  }
}

.row-recap {
  background: #F4F3F0;
}

.section-payment{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  padding: 20px 40px;
  margin-bottom: 40px;
}

.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

.select {
  width: 200px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat;
  font-size: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

</style>
