<template>
  <div class="ribbon ribbon-top-left" :style="css">
    <span>{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    padding: {left: String, top: String},
    size: {width: String, height: String},
    span: {fontSize: String, top: String, right: String, width: String},
    text: {type: String, default: 'Nouveau'}
  },
  computed: {
    css() {
      const vars = {
        padding: {left: '0px', top: '0px', ...this.padding},
        size: {width: '140px', height: '140px', ...this.size},
        span: {
          fontSize: '14px',
          top: '45px',
          right: '-26px',
          width: '216px',
          ...this.span
        }
      };
      return {
        '--ribbon-padding-left': vars.padding.left,
        '--ribbon-padding-top': vars.padding.top,
        '--ribbon-width': vars.size.width,
        '--ribbon-height': vars.size.height,
        '--ribbon-span-font-size': vars.span.fontSize,
        '--ribbon-span-top': vars.span.top,
        '--ribbon-span-right': vars.span.right,
        '--ribbon-span-width': vars.span.width
      }
    }
  }
}
</script>

<style lang='scss' scoped>

  .ribbon {
    width: var(--ribbon-width);
    height: var(--ribbon-height);
    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    border: 5px solid #E2B607;
  }
  .ribbon span {
    align-items: center;
    display: flex;
    height: 22px;
    justify-content: center;
    position: absolute;
    width: 200%;
    background-color: #FFCE08;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: calc(min(14px, var(--ribbon-span-font-size)));
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
  }

  /* top left*/
  .ribbon-top-left {
    top: calc(-10px + var(--ribbon-padding-top));
    left: calc(-10px + var(--ribbon-padding-left));
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    top: calc(50% - 22px);
    transform: rotate(-45deg);
    transform-origin: bottom;
  }
</style>
