<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide class="slider-home" v-for="slide in sliderOrDefault" :key="slide.id">
      <div class="slider-bg">
        <a v-bind:href="slide.link" v-if="slide.link"><img class="slider-img" :src="slide.image"></a>
        <img class="slider-img" :src="slide.image" v-if="!slide.link">
      </div>
      <div class="slider-content" v-if="slide.title || slide.content || slide.link">
        <h2 v-if="slide.title">{{slide.title}}</h2>
        <h3 v-if="slide.content">{{slide.content}}</h3>
        <a class="btn slider-content-btn" v-bind:href="slide.link" v-if="slide.link">Commander</a>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev rounded-circle" v-if="slider.length > 1" slot="button-prev"></div>
    <div class="swiper-button-next rounded-circle" v-if="slider.length > 1" slot="button-next"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import image from 'images/home_bg.png'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['slider', 'slider-height'],
  data() {
    return {
      swiperOption: this.slider.length > 1 ? {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true
      } : undefined,
      defaultSlider: [{
        id: 0,
        image: image,
        title: 'Frais. Local. Livré.',
        content: 'Le Club Tiossane vous livre des courses locales de qualité, disponibles du lundi au samedi. Commandez jusqu’à 21h la veille.'
      }]
    }
  },
  computed: {
    sliderOrDefault: function () {
      return this.slider && this.slider.length ? this.slider : this.defaultSlider;
    }
  }
}
</script>

<style lang='scss' scoped>
  .swiper-slide.slider-home {
    font-family: Recoleta;
    font-style: normal;
    padding: 0;
    .slider-bg {
      width: 100%;
    }
    .slider-content {
      z-index: 100;
      width: 60vw;
      text-align: center;
      background-color: #e97834;
      padding: 25px 25px;
      margin: -1rem auto auto;
      position: relative;
      h2 {
        color: #fff;
        font-weight: bold;
        font-size: 35px;
        line-height: 100%;
        width: 100%;
        margin-bottom: 15px;
      }
      h3 {
        color: #fff;
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    background-color: #e97834;
    color: #fff;
    height: 2.7rem;
    width: 2.7rem;
    top: 22.5vh
  }

  .swiper-button-prev {
    margin-left: 0.7rem;
  }

  .swiper-button-next {
    margin-right: 0.7rem;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 1.2rem;
  }

  .slider-img {
    width: 100%;
    height: 100%;
  }

  .slider-content-btn {
    background-color: #e97834;
    border-color: white;
    border-width: 2px;
    font-weight: bold;
    color: white;
    font-size: 24px;
    padding: 0 12px;
    margin-top: 3px;
  }
</style>