<template>
  <div class="order-item-container">
    <product-template
      :product="box"
      :likes="[]"
      :initQuantity="box.quantity"
      :inCart="false"
      :isBox="true"
      :status="PRODUCT_STATUS.validated"
      :showFreq="true"
      :withLikes="false"
      :oneShot="box.oneShot"
    ></product-template>
  </div>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'
export default {
  props: ['box'],
  data() {
    return {
      PRODUCT_STATUS
    }
  }
}
</script>
