<template>
  <div class="sidebar-items">
    <h2>Filter les produits:</h2>

    <div class="form-group form-check mt-5">
      <input @change="setPromotion($event)" type="checkbox" v-model="promotion" class="form-check-input" id="examplePromotions">
      <label class="form-check-label" for="examplePromotions">Promotions</label>
    </div>

    <div class="form-group form-check">
      <input @change="setPromotion($event)" type="checkbox" v-model="biologique" class="form-check-input" id="exampleBiologique">
      <label class="form-check-label" for="exampleBiologique">Biologique</label>
    </div>

    <div class="form-group form-check">
      <input @change="setPromotion($event)" type="checkbox" v-model="local" class="form-check-input" id="exampleLocal">
      <label class="form-check-label" for="exampleLocal">Local</label>
    </div>
  </div>
</template>

<script>
import service from './packs/services'
import moment from 'moment'
import eventBus from './packs/eventBus'

export default {
  data: function () {
    return {
      promotion: false,
      local: false,
      biologique: false
    }
  },
  mounted() {
  },

  computed: {
  },

  methods: {
    setPromotion(event) {
      eventBus.$emit("ProductFilter", {
        promotion: this.promotion,
        local: this.local,
        biologique: this.biologique
      });
    }
  }
}
</script>

<style scoped>

</style>
