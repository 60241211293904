<template>
<div v-if="cart" class="container-fluid px-0">
  <div class="container-fluid pl-lg-5">
    <div class="row flex-xl-nowrap">
      <div class="col-md-3 col-xl-3 bd-sidebar">
        <div class="d-flex sidebar-item">
          <a :href="`/rubrique/${rubrique.id}`" class="btn btn-lg btn-secondary my-4">Continue Shopping</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('NEXT-DELIVERY')" :class="active('NEXT-DELIVERY')">
          <a class="link-not-decorated" >Ma prochaine livraison</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('MANAGE-DELIVERY')" :class="active('MANAGE-DELIVERY')">
          <a class="d-flex link-not-decorated">Gestion des livraisons</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('LIKED-PRODUCTS')" :class="active('LIKED-PRODUCTS')">
          <a class="d-flex link-not-decorated">Produits Favoris</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('FAQ')" :class="active('FAQ')">
          <a class="d-flex link-not-decorated">FAQ</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('HISTORY')" :class="active('HISTORY')">
          <a class="link-not-decorated">Historique de livraison</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('ACCOUNT-SETTINGS')" :class="active('ACCOUNT-SETTINGS')">
          <a class="d-flex link-not-decorated">Informations du compte</a>
        </div>
        <div class="d-flex sidebar-item" @click="selectMenu('FEEDBACK')" :class="active('FEEDBACK')">
          <a class="link-not-decorated">Feedback</a>
        </div>
        <div class="d-flex sidebar-item" v-if="false" @click="selectMenu('NOTIFICATION')" :class="active('NOTIFICATION')">
          <a class="d-flex link-not-decorated">Notifications</a>
        </div>
        <div class="d-flex sidebar-item">
          <a href="/users/sign_out" data-method="delete" class="link-not-decorated" >Déconnexion</a>
        </div>
      </div>
      <div class="col-md-9 col-xl-9 py-md-3 pl-md-5 my-account">
        <div v-if="message" class="alert alert-warning">{{ message }}</div>
        <next-delivery :userId="user.id" v-if="isSelected('NEXT-DELIVERY')"/>
        <delivery-settings :userId="user.id" v-if="isSelected('MANAGE-DELIVERY')"/>
        <account-settings :userId="user.id" v-if="isSelected('ACCOUNT-SETTINGS')"/>
        <feedback :userId="user.id" v-if="isSelected('FEEDBACK')"/>
        <notifications :userId="user.id" v-if="isSelected('NOTIFICATION')"/>
        <liked-products :userId="user.id" v-if="isSelected('LIKED-PRODUCTS')"/>
        <faq v-if="isSelected('FAQ')"/>
        <orders-history v-if="isSelected('HISTORY')"/>
        <orders-history-detail :orderId="todayOrder.id" v-if="isSelected('HISTORY-DETAIL')"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import service from './packs/services'
import viewHelper from './packs/zone_helper'
import EventBus from './packs/eventBus'
import moment from 'moment'

export default {
  props: ['user', 'rubrique'],
  data: function () {
    return {
      selectedMenu: 'NEXT-DELIVERY',
      cart: null,
      zone: null,
      todayOrder: null,
      message: null
    }
  },

  async mounted() {

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.message = null
    if (urlParams.has('payment-success')) {
      this.message = 'Votre solde sera mis à jour dans quelques instants. Veuillez actualiser la page'
    }
    await this.init()
    EventBus.$on('DeliveryDateUpdated', function() {
      this.init()
    }.bind(this))

    EventBus.$on('HistoryDetail', function() {
      this.selectMenu('HISTORY-DETAIL')
    }.bind(this))
  },

  computed: {

    unValidated() {
      return this.unValidatedProducts.length > 0
    },

    unValidatedProducts() {
      return this.cart.order_items
    },

    todayOrderTotal() {
      let total = 0
      this.todayOrder.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      let fee = this.todayOrder.delivery_fee ? parseInt(this.todayOrder.delivery_fee) : 0
      return total + fee
    },
  },

  methods: {

    async init() {
      this.cart = await service.getTCartContent()
      this.deliveryDates = []
      this.todayOrder = await service.getTodayOrder()
      this.zone = await service.getZone(this.user.ZoneId)
      this.deliveryFees = await service.getDeliveryFees()
    },

    isSelected(menuItem) {
      return this.selectedMenu == menuItem;
    },

    active(menuItem) {
      return this.isSelected(menuItem) ? 'active' : ''
    },

    selectMenu(menuItem) {
      this.selectedMenu = menuItem;
    },

    async updateDate(updatedDate) {
      if(updatedDate.today) {
        return false;
      }
      if(updatedDate && this.selectedDate && moment(updatedDate.date).isSame(this.selectedDate.date, 'day')) {
        return false;
      }

      if(this.selectedDate) {
        this.selectedDate.selected = false
        this.selectedDate.changed = false
      }
      this.selectedDate = updatedDate
      this.selectedDate.selected = true
      this.selectedDate.changed = true
      EventBus.$emit("DateChanged", {selectedDate: this.selectedDate});
    }

  }
}
</script>

<style lang='scss' scoped>

.row-shopping {
  margin-top: -20px;
}

.sidebar-item {
  cursor: pointer;
}

.my-account {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}
.account-number {
  font-family: 'ITC Souvenir Std Bold';
  font-weight: bold;
}

.active {
  position: relative;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

.active::after {
  position: absolute;
  right: -20px;
  top: 0;
  content: "";
  width: 30px;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  z-index: 100;
}

.delivery-date {
  font-family: Recoleta;
  font-style: normal;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}

.delivery-date-title {
  margin-top: 20px;
  color: #2A6336;
}
.delivery-date-title h3 {
  display: inline;
  font-family: Recoleta;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
}

.delivery-date-title h3 > span {
  font-family: Recoleta;
  font-size: 20px;
}

.day-item {
  background-color: transparent;
  height: 200px;
}

.day-item-header {
  padding: 5px 0px;
  height: 45px;
}

.day-item-header img {
  display: initial;
  height: 35px;
}

.day-item-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 150px;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 25px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.day {
  margin-top: 20px;
  font-family: 'Montserrat';
  font-size: 14px;
  text-transform: capitalize;
}

.month {
  margin-top: 15px;
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 14px;
  color: #2A6336;
}

.fees {
  margin-top: 10px;
}

.amount {
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 15px;
}

.free {
  font-family: Recoleta;
  font-size: 15px;
  font-weight: bold;
  color: #E97834;
}

.currency {
  padding-left: 5px;
  font-family: Recoleta;
  font-size: 14px;
}

.day-item-today {
  .day-item-body {
    background-color: #E97834;
    color: #fff;
    .month{
      color: #fff;
    }
  }
  &:hover {
    .day-item-body {
      cursor: default !important;
      background-color: #E97834 !important;
    }
  }
  .inprogress {
    margin-top: 10px;
    font-size: 12px;
  }
}

.day-item-active {
  .waiting {
    margin-top: 8px;
    font-size: 12px;
    color: #fff;
  }
}

.day-item-active .day-item-body {
  background-color: #2A6336;
}

.day-item-active .day,
.day-item-active .month,
.day-item-active .fees {
  color: #fff;
}

.day-item:hover .day-item-body {
  background-color: #f6faf4;
  cursor: pointer;
}

.day-item-active:hover .day-item-body {
  background-color: #2A6336;
}

.notice-message {
  color: #2A6336;
  text-align: center;
}

</style>
