<template>
  <router-view></router-view>
</template>

<script>

export default {
  data: function () {
    return {
    }
  },
  async mounted() {
    this.$router.push({name: 'orders-history-list'})
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>


</style>
