import { render, staticRenderFns } from "./ProductFrequency.vue?vue&type=template&id=6619a760&scoped=true&"
import script from "./ProductFrequency.vue?vue&type=script&lang=js&"
export * from "./ProductFrequency.vue?vue&type=script&lang=js&"
import style0 from "./ProductFrequency.vue?vue&type=style&index=0&id=6619a760&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6619a760",
  null
  
)

export default component.exports