<template>
  <product-template
    :product="box"
    :likes="likes"
    :initQuantity="0"
    :inCart="true"
    :isBox="true"
    :status="PRODUCT_STATUS.shelf"
    :showFreq="true"
    :withLikes="authenticated"
    :oneShot="box.isOneShotable"
  ></product-template>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'
export default {
  props: ['box', 'cart', 'likes', 'authenticated', 'rubrique', 'category'],
  data() {
    return {
      PRODUCT_STATUS
    }
  }
}
</script>
