<template>
  <div class="payment-form py-3">
    <div class="main-div">
      <div>
        <img class="img-sm" :src="require('images/orange_money_logo.jpeg')">
      </div>
      <div class="panel">
        <h3>Montant à créditer : </h3><h2>{{amount}} FCFA</h2>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errors.length">
        <ul>
          <li class="alert-item" v-for="(error, index) in errors" :key="index">
            {{ error }}
            </li>
        </ul>
      </div>
      <div class="container form-section">
        <div class="row justify-content-center">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div class="section-body">
              <div class="form-row">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    1 - Veuillez, composez <strong>#144#391*CodeSecret#</strong> sur votre téléphone
                  </li>
                  <li class="list-group-item">
                    2 - Vous allez recevoir un code de 6 chiffres généré par Orange Money
                  </li>
                  <li class="list-group-item">
                    3 - Renseignez le code sur la page de paiement puis cliquez sur le bouton "Payer" 
                  </li>
                  <li class="list-group-item">
                    4 - Retournez sur votre profil sur le site marchand et raffraichissez !
                  </li>
                </ul>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">+221</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="inputPhone"
                      name="phone"
                      v-model="phoneNumber"
                      placeholder="Entrer votre numéro Orange Money" >
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <input
                    type="text"
                    class="form-control"
                    id="inputOtp"
                    name="otpCode"
                    v-model="otpCode"
                    placeholder="Code de 6 chiffres" >
                </div>
              </div>
              <div class="form-group">
                <button :disabled="isProcessing" @click="processPayment()" class="btn btn-primary">Payer</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  </div>
</template>

<script>

import services from './packs/services'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
  props: ["amount"],
  data: function () {
    return {
      phoneNumber: '',
      otpCode: '',
      errors: [],
      isProcessing: false
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {

    /**
     * Validate the form
     */
    isValid() {
      this.errors = [];
      const validPhoneNumber = parsePhoneNumberFromString(this.phoneNumber, 'SN');
      if (!validPhoneNumber || !validPhoneNumber.isValid()) {
        this.errors.push('Numéro de téléphone incorrect, saisir un numéro comme celui-ci 772223366');
      }
      if (!this.otpCode || this.otpCode.length != 6 ) {
        this.errors.push('Code incorrect ! Veuillez saisir le code de 6 chiffres généré sur votre téléphone');
      }
      return this.errors.length === 0;
    },

    /**
     * Process the payment
     */
    async processPayment() {
      if(this.isValid()) {
        const validPhoneNumber = parsePhoneNumberFromString(this.phoneNumber, 'SN');
        if (validPhoneNumber) {
          try {
            this.isProcessing = true
            await services.makeOMPayment(this.phoneNumber, this.amount, this.otpCode)
            window.location.href = '/account'
          } catch (err) {
            this.isProcessing = false
            this.errors.push('Une erreur s\'est produite lors du paiement. Detail : ' + err.response.data.message)
          }
        }
      }
    }

  }
}
</script>

<style lang='scss' scoped>

.form-group.required .control-label:after {
  content:"*";
  color:red;
  margin-left: 3px;
}

.form-group .control-label {
  font-family: 'Recoleta';
  font-weight: 600;
  font-size: 15px;
}

.form-heading {
  color: #fff;
  font-size: 23px;
}

.panel {
  margin-top: 30px;
  margin-bottom: 20px;
}

.panel h2 {
  color: #2c3e50;
  font-size: 23px;
  font-weight: bold;
  margin: 0 0 8px 0;
  display: inline;
}

.panel h3 {
  color: #2c3e50;
  font-size: 18px;
  margin: 0 0 8px 0;
  display: inline;
}

.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}

.alert-item {
  text-align: left;
}

.payment-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.main-div {
  li {
    text-align: left;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
}

.payment-form .form-group {
  margin-bottom: 10px;
}

.form-group {
  text-align: left;
}

.payment-form {
  text-align: center;
}

.forgot a {
  color: #777777;
  font-size: 14px;
  text-decoration: underline;
}

.payment-form .btn.btn-primary {
  color: #ffffff;
  background-color: #2A6336;
  border-color: #2A6336;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
}

.forgot {
  text-align: left;
  margin-bottom: 30px;
}

.botto-text {
  color: #ffffff;
  font-size: 14px;
  margin: auto;
}

.payment-form .btn.btn-primary.reset {
  background: #ff9900 none repeat scroll 0 0;
}

.back {
  text-align: left;
  margin-top: 10px;
}

.back a {
  color: #444444;
  font-size: 13px;
  text-decoration: none;
}

.img-sm {
  width: 250px;
  height: auto;
}

</style>
