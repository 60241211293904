<template>
  <div class="row" v-if="cart">
    <div class="col-4 mb-3" v-for="item in items" v-bind:key="item.id">
      <box-item :box="item" :cart="cart" :authenticated="authenticated" />
    </div>
  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import services from './packs/services'

export default {
  props: ["items"],
  data: function () {
    return {
      cart: null,
      authenticated: false
    }
  },
  async mounted() {
    this.cart = await services.getTCartContent()
    let user = services.getCurrentUser()
    if(user.id) this.authenticated = true
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
