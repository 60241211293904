<template>
  <div class="d-flex flex-col flex-wrap justify-content-around">
    <order-box-item v-bind:box="box" v-for="box in boxes" :key="`obi-${box.id}-${box.quantity}`"/>
    <order-item v-bind:item="item" :likes="likes" v-for="item in sortedItems" v-bind:key="item.id"/>
  </div>
</template>

<script>
import lodash from 'lodash'
import services from './packs/services'

export default {
  props: ["items", "boxes"],
  data: function () {
    return {
      likes: null
    }
  },
  async mounted() {
    this.likes = await services.getLikes()
  },

  computed: {

    sortedItems() {
      return lodash.sortBy(this.items, [function(o) { return o.product.order }])
    }
  }
}
</script>

<style scoped lang="scss">
  .order-item-container {
    display: flex;
    justify-content: center;
    margin: 0 0 1rem;
    width: 100%;

    @media screen and (min-width: 767px) {
      margin: 0 1rem 1rem 0;
      max-width: 312px;
      width: calc(50% - 1rem);
    }
  }
</style>
