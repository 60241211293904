<template>
  <product-template
    :product="item.product"
    :likes="likes"
    :oneShot="item.oneShot"
    :initQuantity="item.quantity"
    :inCart="true"
    :status="PRODUCT_STATUS.readonly"
    :showFreq="false"
    :withLikes="false"
  ></product-template>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'

export default {
  props: ['item', 'likes'],
  data() {
    return {
      PRODUCT_STATUS,
    }
  },
}
</script>
