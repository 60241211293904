import httpClient from './http';

export default {

  makePayment: (amount, payment_ref) => {
    return httpClient.post('/api/online_payment/wave/payment', {
      amount,
      currency: 'XOF',
      payment_ref
    }).then(res => res.data);
  },

  /**
   * Create payment for ngenius
   */
  initNgeniusPayment: (amount, payment_ref) => {
    const payload = {amount, payment_ref}
    return httpClient.post(`/api/online_payment/card/payment`, payload).then(res => res.data)
  },
}
