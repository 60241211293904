import httpClient from './http';
import lodash from 'lodash';

export default {

  getUser: (userId) => {
    return httpClient.get(`/account/${userId}`)
      .then(res => res.data);
  },

  getProduct: (productId) => {
    return httpClient.get(`/api/product/${productId}`)
      .then(res => res.data);
  },

  getBox: (boxId) => {
    return httpClient.get(`/api/box/${boxId}`)
      .then(res => res.data);
  },

  getBoxes: () => {
    return httpClient.get(`/api/boxes`)
      .then(res => res.data);
  },

  getDeliveryFees: () => {
    return httpClient.get(`/api/fees`)
      .then(res => res.data);
  },

  getLikes: () => {
    return httpClient.get(`/api/products/likes`)
      .then(res => res.data);
  },

  getOrders: () => {
    return httpClient.get(`/orders`)
      .then(res => res.data);
  },

  getOrderById: (orderId) => {
    return httpClient.get(`/orders/${orderId}`)
      .then(res => res.data);
  },

  getCartContent: () => {
    return httpClient.get(`/account/cart`)
      .then(res => res.data);
  },

  getTCartContent: async () => {
    let result = await httpClient.get(`/account/t-cart`).then(res => res.data)
    if(result && result.order_items){
      result.order_items = lodash.sortBy(result.order_items, [function(o) { return !o.panier && o.product.order }])
    }
    return result
  },

  getCurrentUser: () => {
    return httpClient.get(`/api/account/current`)
      .then(res => res.data);
  },

  getTodayOrder: () => {
    return httpClient.get(`/api/account/today-order`)
      .then(res => res.data);
  },

  getGlassBalance: () => {
    return httpClient.get(`/api/account/glass-balance`)
      .then(res => res.data);
  },

  /**
   * Order product quantity
   */
  setCartQuantity: (productId, quantity) => {
    return httpClient.put(`/product/${productId}/c_quantity`, {
      quantity
    }).then(res => res.data);
  },

  /**
   * Order box quantity
   */
  setBoxQuantity: (boxId, quantity) => {
    return httpClient.put(`/box/${boxId}/c_quantity`, {
      quantity
    }).then(res => res.data);
  },

  /**
   * Order product quantity
   */
  setQuantity: (productId, quantity) => {
    return httpClient.put(`/product/${productId}/quantity`, {
      quantity
    }).then(res => res.data);
  },

  /**
   * Order product quantity
   */
  setOrderBoxQuantity: (boxId, quantity) => {
    return httpClient.put(`/box/${boxId}/quantity`, {
      quantity
    }).then(res => res.data);
  },

  /**
   * Order product quantity
   */
  setRecurrence: (productId, inCart, oneShot) => {
    const endpoint = inCart ? `/product/${productId}/c_recurrence` : `/api/product/${productId}/recurrence`

    return httpClient.put(endpoint, {
      oneShot: oneShot
    }).then(res => res.data);
  },

  /**
   * Order box recurrence
   */
  setBoxRecurrence: (boxId, inCart, oneShot) => {
    const endpoint = inCart ? `/box/${boxId}/c_recurrence` : `/api/box/${boxId}/recurrence`

    return httpClient.put(endpoint, {
      oneShot: oneShot
    }).then(res => res.data);
  },

  /**
   * Set like
   */
  setLike: (productId, liked) => {
    return httpClient.put(`/product/${productId}/like`, {
      liked
    }).then(res => res.data);
  },

  /**
   * Delete
   */
  deleteProduct: (productId) => {
    return httpClient.put(`/api/product/${productId}/delete`).then(res => res.data);
  },

  /**
   * Delete
   */
  deleteBox: (boxId) => {
    return httpClient.put(`/api/box/${boxId}/delete`).then(res => res.data);
  },

  /**
   * Get products
   */
  getProducts: () => {
    return httpClient.get(`/api/products`)
      .then(res => res.data);
  },

  /**
   * Get consignes
   */
  getConsignes: () => {
    return httpClient.get(`/api/consignes`)
      .then(res => res.data);
  },

  /**
   * Get FAQs
   */
  getFaq: () => {
    return httpClient.get(`/api/faq`)
      .then(res => res.data);
  },

  /**
   * Get zones
   */
  getZones: () => {
    return httpClient.get(`/zones`)
      .then(res => res.data);
  },

  /**
   * Get zone
   */
  getZone: (zoneId) => {
    return httpClient.get(`/api/zone/${zoneId}`).then(res => res.data)
  },

  /**
   * Get zones
   */
  getCategories: () => {
    return httpClient.get(`/api/categories`)
      .then(res => res.data);
  },

  /**
   * Delivery settings
   */
  updateDeliverySettings: (accountId, status, deliveryDate) => {
    return httpClient.put(`/account/${accountId}/delivery`, {
      status, delivery_date: deliveryDate
    }).then(res => res.data);
  },

  /**
   * Delivery date
   */
  updateDeliveryDate: (accountId, deliveryDate, delivery_fee) => {
    return httpClient.put(`/account/${accountId}/delivery`, {
      delivery_date: deliveryDate,
      delivery_fee: delivery_fee
    }).then(res => res.data);
  },

  /**
   * Validate Cart
   */
  validateCart: () => {
    return httpClient.post(`/api/account/validate-cart`, {}).then(res => res.data)
  },

  /**
   * Validate Cart
   */
  addOrderComment: (orderComment) => {
    return httpClient.post(`/api/account/order-comment`, {orderComment}).then(res => res.data)
  },

  /**
   * Create a user
   */
  createUser: (user) => {
    return httpClient.post(`/api/account/signup`, {user}).then(res => res.data)
  },

  validateUserAddress: (user) => {
    return httpClient.post(`/api/account/address`, {user}).then(res => res.data)
  },

  /**
   * update localisation
   */
  updateLocalisation: (user) => {
    return httpClient.put(`/api/account/localization`, {user}).then(res => res.data)
  },

  /**
   * update user infos
   */
  updateUser: (user) => {
    return httpClient.put(`/api/account`, {user}).then(res => res.data)
  },

  /**
   * get today deliveries
   */
  getTodayDeliveries: () => {
    return httpClient.get(`/admin/api/deliveries`).then(res => res.data)
  },

  /**
   * Change Truck
   */
  cancelUnloading: (delivery_id) => {
    return httpClient.put(`/admin/api/cancel-unloading/${delivery_id}`, {delivery_id}).then(res => res.data)
  },

  /**
   * Change Truck
   */
  changeTruck: (orderId, deliveryTruckDayId) => {
    return httpClient.put(`/admin/api/orders/${orderId}/change-truck`, {deliveryTruckDayId}).then(res => res.data)
  },

  /**
   * Mark mercato as done
   */
  markMercatoAsDone: () => {
    return httpClient.put(`/admin/api/orders/end-mercato`, {}).then(res => res.data)
  },

  /**
   * Get mercato status
   */
  getMercatoStatus: () => {
    return httpClient.get(`/admin/api/orders/mercato-status`, {}).then(res => res.data)
  },
  /**
   * Send contact email
   */
  sendContactMessage: (name, email, message) => {
    return httpClient.post(`/api/mails/contact`, {name, email, message}).then(res => res.data)
  },

  /**
   * Send feedback email
   */
  sendFeedback: (name, email, type, message) => {
    return httpClient.post(`/api/mails/feedback`, {name, email, type, message}).then(res => res.data)
  },

  /**
   * Init OM payment
   */
  makeOMPayment: (phoneNumber, amount, otpCode) => {
    return httpClient.post(`/api/online_payment/orange/payment`, {phoneNumber, amount, otpCode}).then(res => res.data)
  },

  /**
   * Get OM Deeplink
   */
  getOMDeepLink: (amount) => {
    return httpClient.post(`/api/online_payment/orange/deep-link`, {amount}).then(res => res.data)
  },

  /**
   * get consigne
   */
  getOrderConsignes: (order_id) => {
    return httpClient.get(`/driver/${order_id}/consignes`).then(res => res.data)
  },

  getDriverOrderById: (orderId) => {
    return httpClient.get(`/driver/orders/${orderId}`)
      .then(res => res.data);
  },

  getDriverUserById: (userId) => {
    return httpClient.get(`/driver/users/${userId}`)
      .then(res => res.data);
  },

  /**
   * get consigne
   */
  deliverOrder: (order_id, payload) => {
    return httpClient.post(`/driver/${order_id}/deliver`, payload).then(res => res.data)
  },

  /**
   * Validate Quantities
   * @param {*} order_id
   * @param {*} payload
   * @returns
   */
  deliverQuantities: (order_id, payload) => {
    return httpClient.post(`/driver/api/${order_id}/deliver-quantities`, payload).then(res => res.data)
  },

  /**
   * get consigne unloading
   */
  getConsigneUnloading: (day_id) => {
    return httpClient.get(`/driver/api/${day_id}/consigne-unloading`).then(res => res.data)
  },

  /**
   * get consigne
   */
  saveConsigneUnloading: (day_id, payload) => {
    return httpClient.post(`/driver/api/${day_id}/consigne-unloading`, payload).then(res => res.data)
  },

  /**
   * get consigne unloading
   */
  getDayCash: (day_id) => {
    return httpClient.get(`/driver/api/${day_id}/cash`).then(res => res.data)
  },

  /**
   * get consigne
   */
  getLoadings: () => {
    return httpClient.get(`/admin/api/odoo/loadings`).then(res => res.data)
  },

  /**
   * Sync loading
   */
  syncLoading: () => {
    return httpClient.put(`/admin/api/odoo/loadings`, {}).then(res => res.data)
  },

  /**
   * Get Report
   */
    getReport: (currentDate) => {
      return httpClient.get(`/admin/api/report/summary`, {params: { current_date: currentDate } }).then(res => res.data)
    },


}
