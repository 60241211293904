<template>

  <div class="container">
    <div v-show="step == 'address'" class="row justify-content-center">
      <div class="col-10 py-2">
        <div class="row">
          <div class="section-title col-12">
            <img :src="require('images/two.svg')" />
            Informations de livraison (optionnel)
          </div>

          <div class="section-body">
            <h2 class="section-subtitle-2">
              Contact de la personne qui sera à la maison pendant la livraison
            </h2>

            <div class="form-row">
              <div class="form-group col-md-6 not-required">
                <label class="control-label" for="contactFirstName">Nom complet</label>
                <input placeholder="Nom complet" autofocus="autofocus" type="text" name="contactFirstName" 
                  v-model="user.contactFirstName" 
                  class="form-control" :class="validation_class_for('contactFirstName')" />
                <div class="invalid-feedback">
                  {{first_error_on_attribute('contactFirstName')}}
                </div>
              </div>

              <div class="form-group col-md-6 not-required">
                <label class="control-label" for="contactPhone">Téléphone</label>
                <input placeholder="Numéro de téléphone" autofocus="autofocus" type="text" name="contactPhone" 
                  v-model="user.contactPhone" 
                  class="form-control" :class="validation_class_for('contactPhone')" />
                <div class="invalid-feedback">
                  {{first_error_on_attribute('contactPhone')}}
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12 not-required">
                <label class="control-label" for="contactPhone">Description de votre adresse</label>
                <textarea v-model="user.addressDetails" rows="8" placeholder="Exemple : Adresse / Lieu de livraison / Point répère / couleur façade / Numéro villa" autofocus="autofocus" name="addressDetails" 
                  class="form-control" :class="validation_class_for('addressDetails')"></textarea>
                <div class="invalid-feedback">
                  {{first_error_on_attribute('addressDetails')}}
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="form-group d-flex justify-content-center">
          <button @click="validateAddress()" type="button" class="btn btn-primary btn-lg" >Continuer</button>
        </div>
      </div>
    </div>

    <div v-show="step == 'geo'" class="row justify-content-center">
      <div class="col-10 py-2">
        <div class="row">
          <div class="section-body">
            <h2 class="section-subtitle-2">
              Aidez-nous à vous livrer plus facilement en vous positionnant sur la carte de la ville !
            </h2>
            <locate-me :user="user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from './packs/services'

export default {
  props: ["userId"],
  data: function () {
    return {
      step: 'address',
      user: null,
      errors: [],
      reference: {label: 'Ami client du Club', value: 'friends'}
    }
  },
  async mounted() {
    this.user = services.getUser(this.userId)
  },
  computed: {

    referenceList() {
      return [      
        {label: 'Ami client du Club', value: 'friends'},
        {label: 'Instagram', value: 'instagram'},
        {label: 'Facebook', value: 'facebook'},
        {label: 'Recherche Internet', value: 'internet'},
        {label: 'Autres', value: 'other'}
      ]
    },
  },
  methods: {

    async validate() {
      try {
        if(!this.user.ZoneId) {
          this.$swal({
            icon: 'warning',
            cancelButtonText: 'OK',
            cancelButtonColor: '#2A6336',
            title: "Localisation",
            text: "Vous devez selectionner une zone valide",
            showCancelButton: true,
            showConfirmButton: false
          });
          return
        }
        this.user.reference = this.reference.value
        await services.updateLocalisation(this.user)
        this.errors = []
        window.location.href = '/account'
      } catch (err) {
        if(err.response.status == 422) { 
          this.errors = err.response.data
        }
      }  
    },

    async validateAddress() {
      try {
        this.user = await services.validateUserAddress(this.user)
        this.errors = []
        this.step = 'geo'
      } catch (err) {
        if(err.response.status == 422) { 
          this.errors = err.response.data
        }
      }  
    },

    validation_class_for(field_name) {
      if(this.errors) {
        let error = this.errors[field_name] 
        return error ? 'is-invalid' : ''
      }
      return ''
    },

    first_error_on_attribute(field_name) {
      if(this.errors && this.errors[field_name]) {
        let firstError = this.errors[field_name][0] 
        return firstError
      }
      return null
    }

  }
}
</script>

<style lang='scss' scoped>

.form-group.not-required .control-label:after {
  content:"(optionnel)";
  color: rgba(41, 41, 41, 0.936);
  margin-left: 3px;
  font-size: 14px;
  font-weight: normal;
}

.form-group .control-label {
  font-family: 'Recoleta';
  font-weight: 600;
  font-size: 18px;
}
</style>
