<template>
  <div class="d-flex flex-wrap justify-content-center" v-if="cart">
    <div class="item mb-4 mx-2" v-for="item in filteredItems" v-bind:key="item.item_id">
      <product-item v-if="!item.box_items"
                    :product="item"
                    :cart="cart"
                    :likes="likes"
                    :authenticated="authenticated"
                    :rubrique="rubrique"
                    :category="category" />
      <box-item v-if="item.box_items"
                :box="item"
                :cart="cart"
                :authenticated="authenticated"
                :rubrique="rubrique"
                :category="category" />
    </div>

    <div class="category-links mt-4">
      <a class="category-link --left" v-if="prev_category" :href="`/category/${prev_category.id}`">
        <i class="fas fa-arrow-left"></i>
        {{ prev_category.title }}
      </a>
      <a class="category-link --right" v-if="next_category" :href="`/category/${next_category.id}`">
        {{ next_category.title }}
        <i class="fas fa-arrow-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import services from './packs/services'

export default {
  props: ["item_ids", "rubrique", "category", "next_category", "prev_category"],
  data: function () {
    return {
      products: [],
      boxes: [],
      items: [],
      filteredItems: [],
      cart: null,
      likes: [],
      authenticated: false
    }
  },
  async mounted() {

    let [products, boxes, likes, cart, user] = await Promise.all([
      services.getProducts(), 
      services.getBoxes(),
      services.getLikes(),
      services.getTCartContent(),
      services.getCurrentUser()
      ]);
    this.products = products, this.boxes = boxes, this.likes = likes, this.cart = cart
    if(user.id) this.authenticated = true

    this.item_ids.forEach(item_id => {
      let item_compute_id = `${item_id.item_type}_${item_id.item_id}`
      // if the item already exists we avoid adding it twice
      let exists = this.filteredItems.find(p => p.item_id === item_compute_id)
      if (!exists) {
        if (item_id.item_type == 'product') {
          let p = this.products.find(p => p.id === item_id.item_id)
          
          if (p !== undefined) {
            p.item_id = item_compute_id
            this.filteredItems.push(p)
          }
        } else {
          let p = this.boxes.find(p => p.id === item_id.item_id)
          if (p !== undefined) {
            p.item_id = item_compute_id
            this.filteredItems.push(p)
          }
        }
      }
    })

    eventBus.$on('ProductFilter', function(filterOptions) {
      this.filteredItems = this.items.filter(item => {
        if(filterOptions.promotion && !item.promotion) return false
        if(filterOptions.local && !item.local) return false
        if(filterOptions.biologique && !item.biologique) return false
        return true
      })
    }.bind(this))
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .item {
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 766px) {
      width: 40%;
    }

    @media screen and (min-width: 992px) {
      width: 30%;
    }
  }

  .category-links {
    display: flex;
    font-weight: bold;
    justify-content: space-around;
    width: 100%;
  }

  .category-link {
    align-items: center;
    background: white;
    border: solid 2px var(--brand-orange);
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.15);
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    padding: 0.7rem 1rem;
    width: 40%;

    svg {
      color: var(--brand-orange);
      font-size: 0.8rem;
    }

    &.--left svg {
      margin-right: 0.5rem;
    }

    &.--right svg {
      margin-left: 0.5rem;
    }
  }
</style>
