<template>
  <div v-if="cart" class="container">
    <div class="row p-2">
      <div class="col-12 section-payment">
        <div class="row row-payment border-bottom">
          <div class="col-12">
            Votre solde disponible
            <span class="amount color-red">{{user.balance | formatAmount}}</span>
            <span class="currency color-red">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Total de la commande</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderTotal | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Valeur consigne récupérable *</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{glassAmount | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Frais de livraison</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderFees | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment row-recap">
          <div class="col-8">
            <span>Total dû à la livraison</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{amountDue | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment row-recap border-bottom border-top px-3">
          <div class="col-12">
            <h3 class="title t-3">Spécifiez le montant que vous voulez créditer</h3>
            <div class="row">
              <div class="col-6">
                <input type="radio" id="next-delivery" value="next-delivery" @change="onPaymentTypeChanged('nextDelivery')" v-model="paymentMode">
                <label for="next-delivery">Payer la prochaine livraison</label>
                <br>
                <input type="radio" id="manual" value="manual" @change="onPaymentTypeChanged(null)" v-model="paymentMode">
                <label for="manual">Saisir un montant manuellement </label>
                <br>
              </div>
              <div class="col-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-10">
                    <input class="form-control" type="text" v-model="paymentAmount" />
                  </div>
                  <label class="col-sm-2 col-form-label currency">FCFA</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-payment row-recap border-bottom px-3">
          <div class="col-12">
            <div class="row">
              <div class="col-9"></div>
              <div class="col-3 text-right">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <span class="fee">Frais</span>
                      <span class="amount" v-if="mode == 'om'">1%</span>
                      <span class="amount" v-if="mode == 'wave'">1%</span>
                      <span class="amount" v-if="mode == 'cb'" >2%</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span class="fee">Total</span>
                      <span class="amount">{{total | formatAmount}}</span>
                      <span class="currency">FCFA</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-payment row-action">
          <div class="col-7 d-flex justify-content-end align-content-center text-right">
            <button type="button" @click="pay()" class="btn btn-lg btn-secondary ml-2">Payer par {{paymentProvider}}</button>
          </div>
        </div>
      </div>
    </div>

    <form ref="formPayment" target="_parent" ng-cloak action="/online_payment/orange/payment" method="GET" >
      <input type="hidden" id="amount" name="amount" v-model="amountToPay" />
    </form>
  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import service from './packs/services'
import wave from './packs/wave'

export default {
  props: ['userId', 'mode'],
  data: function () {
    return {
      user: null,
      order: null,
      cart: null,
      boxes: [],
      likes: null,
      needValidateDate: false,
      selectedDate: null,
      paymentMode: 'next-delivery',
      paymentProvider: '',
      paymentProviders: {
        'wave': 'WAVE',
        'om': 'Orange Money',
        'cb': 'Carte bancaire'
      },
      paymentAmount: null,
      amountToPay: 0,
      amountToCharge: 0,
      startPayment: false,
      delivery_fee: 0
    }
  },
  async mounted() {
    this.refresh()
  },

  computed: {

    amountDue() {
      if (this.user.balance >= this.orderTTCIncludingGlass) return 0
      return this.orderTTCIncludingGlass - this.user.balance
    },

    orderTTC() {
      return this.orderTotal
    },

    orderTTCIncludingGlass() {
      return this.orderTTC + this.glassAmount
    },

    glassAmount() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.glass_amount
      });
      return total
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },

    orderFees() {
      return this.delivery_fee
    },

    /**
     * Compute the amount total to pay
     */
    total() {
      this.amountToPay = 0
      let amount = parseInt(this.paymentAmount);
      if(this.mode == 'om' && isFinite(amount) && amount > 0) {
        let percentOM = 0.99
        this.amountToPay = Math.ceil(amount / percentOM)
        return this.amountToPay;
      }

      if (this.mode == 'cb' && isFinite(amount) && amount > 0) {
        let percent = 0.98;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }

      if (this.mode == 'wave' && isFinite(amount) && amount > 0) {
        let percent = 0.99;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }
      return this.amountToPay;
    },

  },
  methods: {

    async refresh() {
      this.user = await service.getUser(this.userId)
      this.likes = await service.getLikes()
      this.boxes = await service.getBoxes()
      this.order = await service.getCartContent()
      this.cart = await service.getTCartContent()
      this.delivery_fee = this.order.delivery_fee ? parseInt(this.order.delivery_fee) : 0
      this.paymentMode = 'next-delivery'
      this.onPaymentTypeChanged('nextDelivery')
      this.paymentProvider = this.paymentProviders[this.mode]
    },

    /**
     * Method pay
     */
    async pay(){
      const currentLink = window.location.href;
      if (false) {
        this.$swal({
          icon: 'warning',
          cancelButtonText: 'OK',
          cancelButtonColor: '#2A6336',
          title: "Oups!",
          text: "Le montant à payer en ligne doit etre au minimum de 500 Fcfa",
          showCancelButton: true,
          showConfirmButton: false
        });
        return
      }
      switch(this.mode) {
        case 'om':
          this.$refs.formPayment.submit()
          break
        case 'cb':
            /*
            const orderId = (new Date().getTime()) + '-' + this.userId
            sendPaymentInfos(orderId,
              'TIOSN8424',
              '3jQbQoQr?cbfcwH6VMPtfOGlNtlV-Q$Eh#5Ud5ylJt94ESE^zN',
              'www.clubtiossane.sn', currentLink,
              currentLink, this.amountToPay,
              'dakar', '', '', '', '')
            break
            */
          const cb_payment_ref = (new Date().getTime()) + '-' + this.userId
          const cb_resp = await wave.initNgeniusPayment(this.amountToPay, cb_payment_ref)
          window.location.href = cb_resp.payment_url
          break
        case 'wave':
          const payment_ref = (new Date().getTime()) + '-' + this.userId
          const resp = await wave.makePayment(this.amountToPay, payment_ref)
          window.location.href = resp.payment_url
          break
      }

    },

    async cancelPay(){
      this.startPayment = false
    },

    /**
     * On payment changed
     */
    onPaymentTypeChanged(paymentType) {
      let paymentTypeAmount = {
        debt: () => this.user.balance,
        debtNextDelivery: () => this.amountDue,
        nextDelivery: () => this.amountDue
      };

      this.paymentAmount = paymentTypeAmount[paymentType] ? paymentTypeAmount[paymentType]() : 0
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      }
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;

    @media screen and (min-width: 767px) {
      padding-right: 0.25rem;
    }
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: 1.2rem;
    font-weight: 600;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

.amount {
  margin-left: 10px;
  font-family: 'Recoleta';
  font-weight: bold;
}

.currency {
  font-family: 'Montserrat';
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
  span {
    line-height: 40px;
  }
}

.row-recap {
  background: #F4F3F0;
}

.section-payment{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  padding: 20px 40px;
  margin-bottom: 40px;
}

.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

.select {
  width: 200px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat;
  font-size: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

</style>
