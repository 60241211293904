<template>
  <div class="payment-form py-3">
    <div class="main-div">
      <div>
        <img class="img-sm" :src="require('images/orange_money_logo.jpeg')">
      </div>
      <div class="panel">
        <h3>Montant à créditer : </h3><h2>{{amount}} FCFA</h2>
      </div>
      <div class="alert alert-danger" role="alert" v-if="errors.length">
        <ul>
          <li class="alert-item" v-for="(error, index) in errors" :key="index">
            {{ error }}
            </li>
        </ul>
      </div>
      <div class="container form-section">
        <div class="row justify-content-center">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div class="section-body">
              <div class="om_container row justify-content-center">
                <div class="qr_code" v-if="qr_code && !mobile">
                  <img :src="`data:image/png;base64,${qr_code}`" />
                </div>
                <div class="scan" v-if="qr_code && !mobile">
                  <i class="fa fa-lg fa-camera"></i><span>Scanner le QR code pour payer</span>
                </div>

                <div class="download-app">
                  <span class="text-ftm-2 text-center">Télécharger l'application si vous ne l'avez pas déjà, en cliquant sur l'un des liens suivants</span>
                </div>
                <div class="app-buttons">
                    <a href="https://play.google.com/store/apps/details?id=com.orange.myorange.osn" target="_blank">
                      <img :src="require('images/google_play.svg')" alt="Get it on Google Play">
                    </a>
                    <a href="https://apps.apple.com/app/id1039327980" target="_blank">
                      <img :src="require('images/app_store.svg')" alt="Download on the App Store">
                    </a>
                </div>
              </div>

              <div class="form-group text-center" v-if="qr_code && mobile">
                <button @click="startPayment()" class="btn btn-lg btn-secondary mt-3">Payer</button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import services from './packs/services'

export default {
  props: ["amount"],
  data: function () {
    return {
      errors: [],
      qr_code: null,
      mobile: false,
      direct_link: null
    }
  },
  async mounted() {
    const links = await this.processPayment(this.amount)
    this.mobile = links.mobile
    this.qr_code = links.qr_code
    this.direct_link = links.max_it
    setTimeout(() => {
      window.location.href = '/account'
    }, 5 * 60 * 1000);
  },
  computed: {
  },
  methods: {

    startPayment() {
      window.open(this.direct_link, '_blank').focus();
    },

    /**
     * Process the payment
     */
    async processPayment() {
      try {
        const response = await services.getOMDeepLink(this.amount)
        return response
      } catch (err) {
        this.errors.push('Une erreur s\'est produite lors du paiement. Detail : ' + err.response.data.message)
      }
    }

  }
}
</script>

<style lang='scss' scoped>

.form-group.required .control-label:after {
  content:"*";
  color:red;
  margin-left: 3px;
}

.form-group .control-label {
  font-family: 'Recoleta';
  font-weight: 600;
  font-size: 15px;
}

.form-heading {
  color: #fff;
  font-size: 23px;
}

.panel {
  margin-top: 30px;
  margin-bottom: 20px;
}

.panel h2 {
  color: #2c3e50;
  font-size: 23px;
  font-weight: bold;
  margin: 0 0 8px 0;
  display: inline;
}

.panel h3 {
  color: #2c3e50;
  font-size: 18px;
  margin: 0 0 8px 0;
  display: inline;
}

.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}

.alert-item {
  text-align: left;
}

.payment-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.main-div {
  li {
    text-align: left;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
}

.payment-form .form-group {
  margin-bottom: 10px;
}

.form-group {
  text-align: left;
}

.payment-form {
  text-align: center;
}

.forgot a {
  color: #777777;
  font-size: 14px;
  text-decoration: underline;
}

.forgot {
  text-align: left;
  margin-bottom: 30px;
}

.botto-text {
  color: #ffffff;
  font-size: 14px;
  margin: auto;
}

.back {
  text-align: left;
  margin-top: 10px;
}

.back a {
  color: #444444;
  font-size: 13px;
  text-decoration: none;
}

.img-sm {
  width: 250px;
  height: auto;
}

.app-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the buttons */
  margin-top: 20px; /* Adjust as needed */
}

.app-buttons img {
  width: 150px; /* Adjust size as needed */
  height: auto;
}

.scan {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the buttons */
  margin-top: 20px; /* Adjust as needed */
}

.om_container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.qr_code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the buttons */
  margin-top: 20px; /* Adjust as needed */
}

.download-app {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the buttons */
  margin-top: 20px; /* Adjust as needed */
}

</style>
