<template>
  <product-template
    :product="product"
    :likes="likes"
    :oneShot="product.isOneShotable"
    :inCart="true"
    :initQuantity="0"
    :status="PRODUCT_STATUS.shelf"
    :showFreq="true"
    :withLikes="authenticated"
  ></product-template>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'
export default {
  props: ['product', 'cart', 'likes', 'authenticated', 'rubrique', 'category'],
  data() {
    return {
      PRODUCT_STATUS
    }
  }
}
</script>
