<template>
  <product-template
    :product="box"
    :likes="[]"
    :oneShot="oneShot"
    :initQuantity="quantity"
    :inCart="true"
    :isBox="true"
    :status="PRODUCT_STATUS.cart"
    :showFreq="true"
    :withLikes="false"
  ></product-template>
</template>

<script>
import { PRODUCT_STATUS } from './packs/constants'
export default {
  props: ['box', 'quantity', 'oneShot'],
  data() {
    return {
      PRODUCT_STATUS
    }
  }
}
</script>
