<template>
  <div class="row p-4 justify-content-center">
    <div class="col-12 section-cart-products">
      <h2 class="section-title">
        FAQ
      </h2>
      <hr/>
      <div class="col-md-12 col-xl-12"> 
        <div class="accordion" id="accordionExample">
          <div v-for="item in items" v-bind:key="item.id" class="card">
            <div class="card-header" :id="'heading' + item.id">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapse' + item.id" aria-expanded="true" :aria-controls="'collapse' + item.id">
                  {{item.title}}
                </button>
              </h2>
            </div>

            <div :id="'collapse' + item.id" class="collapse show" :aria-labelledby="'heading' + item.id" data-parent="#accordionExample">
              <div class="card-body">
                {{item.answer}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import service from './packs/services'

export default {
  data: function () {
    return {
      items: []
    }
  },
  async mounted() {
    
    let result = await service.getFaq();
    this.items = result;
  },
  computed: {
  },
  methods: {

  }
}
</script>

<style scoped>

.card {
  height: auto;
  margin-bottom: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.card-header {
  border-radius: 10px;
  background: #FFFFFF;
}

.card-body {
  font-family: Montserrat;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

.card button,
.card button:hover,
.card button:active,
.card button:focus {
  color: #000;
  text-decoration: none;
  box-shadow: none;
}
.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

</style>
