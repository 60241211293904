<template>
  <div class="container form-section">
    <div class="row justify-content-center">
      <div class="col-md-6 col-sm-10">
        <h2 class="section-title">Contact</h2>
        <div class="section-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="name">Nom complet</label>
              <input type="text" class="form-control" name="name" v-model="contact.name" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="email">Email</label>
              <input type="text" class="form-control" name="email" v-model="contact.email" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="message">Object</label>
              <textarea name="message" v-model="contact.message" placeholder="Votre message"
                class="form-input bg-white text-gray-700 w-100 appearance-none border rounded-r focus:outline-none focus:border-blue-500" />
            </div>
          </div>
          <div class="form-group">
            <button @click="send()" class="btn btn-lg btn-primary">Envoyer</button> <span class="message-success" v-show="success"><i class="fas fa-check-circle"></i> Votre message a été envoyé avec succès </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  data: function () {
    return {
      contact: {},
      success:false
    }
  },
  async mounted() {
    

  },
  computed: {
  },
  methods: {

    async send(){
      await service.sendContactMessage(this.contact.name, this.contact.email, this.contact.message)
      this.success = true
    }
  }
}
</script>

<style scoped>

</style>
