<template>
  <div class="row p-4 form-section">
    <div class="col-12 mb-5 title-1 text-center">
      Feedback
    </div>
    <div class="col-6 offset-3">
      <div class="section-body">
        <div class="form-group">
          <label class="form-label" for="feedbackType">Objet</label>
            <multiselect 
              :allow-empty="false" 
              v-model="feedbackType" 
              track-by="value" 
              label="label" 
              placeholder="Selectionner le type"
              :options="feedbackTypes"
              :show-labels="false" ></multiselect>
        </div>

        <div class="form-group">
          <label for="feedbackMessage" class="form-label">Message</label>
          <textarea 
            v-model="feedbackMessage" placeholder="Nous sommes à votre disposition"
            
            class="form-input bg-white text-gray-700 w-100 appearance-none border rounded-r focus:outline-none focus:border-blue-500" />
        </div>

        <div class="form-group">
          <button @click="sendFeedback()" class="btn btn-lg btn-primary">Envoyer</button> <span class="message-success" v-show="success"><i class="fas fa-check-circle"></i> Votre message a été envoyé avec succès </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  props: ["userId"],
  data: function () {
    return {
      user: null,
      feedbackType: null,
      feedbackMessage: null,
      success: false
    }
  },
  async mounted() {
    this.user = await service.getUser(this.userId)
  },
  computed: {
    feedbackTypes: function () {
      return [{
        value: "recommendation",
        label: "Recommendation"
      }, {
        value: "pbQuality",
        label: "Problème de qualité"
      }, {
        value: "other",
        label: "Autres"
      }]
    }
  },
  methods: {

    async sendFeedback() {
      await service.sendFeedback(this.user.ownerFirstName, this.user.email, this.feedbackType.label, this.feedbackMessage)
      this.success = true
    }
  }
}
</script>

<style scoped>
.form-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 43px;
}

</style>
