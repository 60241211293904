<template>
  <button
    :class="`product-frequency ${isOneShot ? '' : 'active'} flex-grow-0`"
    @click="onRecurrenceChange"
  >
    <div v-if="frequencyLoading" class="spinner-border spinner-border-sm mx-5"></div>
    <span v-if="!frequencyLoading">
      {{ isOneShot ? 'Livraison unique' : 'Livraison récurrente' }}
    </span>
    <i class="fas fa-caret-down"></i>
  </button>
</template>

<script>
import service from './packs/services'

export default {
  props: [
    'inCart', 'isBox', 'oneShot', 'productId'
  ],

  data() {
    return {
      isOneShot: this.oneShot ?? true,
      frequencyLoading: false,
    }
  },

  methods: {
    async onRecurrenceChange() {
      const recurrence = !this.isOneShot
      this.frequencyLoading = true

      await this.setRecurrence(recurrence)
      this.isOneShot = recurrence
      this.frequencyLoading = false
    },

    async setRecurrence(recurrence) {
      if (this.isBox) {
        await service.setBoxRecurrence(this.productId, this.inCart, recurrence)
      } else {
        await service.setRecurrence(this.productId, this.inCart, recurrence)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .product-frequency {
    background-color: white;
    color: var(--primary-color);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
    font-family: Montserrat;
    font-size: 14px;
    outline: none;
    padding: 7px 10px;

    &.active {
      background-color: var(--primary-color);
      color: white;
    }
  }
</style>
